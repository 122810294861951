<template>
 <div class="w-full" id="Scroll">
      <vx-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            pagination
            :max-items="itemsPerPage"
            search
            :data="CanceledBiadjos"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <div class="flex flex-wrap-reverse items-center">
                <span v-if="queriedItems > 0" class="mr-2 text-xl"
                  >{{ $t("Total") }} :
                </span>
                <span v-if="queriedItems > 0" class="text-primary text-xl">
                  {{ queriedItems }}
                </span>
              </div>
            </div>

            <template slot="thead">
              <vs-th sort-key="id">{{ $t("BiadjoNumber") }}</vs-th>
              <vs-th sort-key="id">{{ $t("VehicleType") }}</vs-th>
              <vs-th sort-key="Price">{{ $t("Price") }}</vs-th>
              <vs-th sort-key="Schedual">{{ $t("Schedual") }}</vs-th>
              <vs-th sort-key="ClosingDate">{{ $t("ClosingDate") }}</vs-th>
              <vs-th sort-key="PaymentStatus">{{ $t("PaymentStatus") }}</vs-th>
              <vs-th sort-key="Status">{{ $t("Status") }}</vs-th>
              <vs-th sort-key="DriverPaymentStatus">
                {{ $t("DriverPaymentStatus") }}
              </vs-th>
              <vs-th sort-key="Rating">{{ $t("Rating") }}</vs-th>
              <vs-th>{{ $t("Actions") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="id font-medium truncate">{{ tr.id }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="VehicleType font-medium truncate">
                      {{ $t(tr.VehicleType) }}
                    </p>
                  </vs-td>

                  <vs-td>
                    <p class="Price">
                      {{ tr.Price | numFormat("###,##0.00") }}
                      {{ $t(tr.CountryCode + "_Currency") }}
                    </p>
                  </vs-td>

                  <vs-td>
                    <p class="Schedual">
                      {{ tr.Schedual | formatDate("yyyy/MM/dd HH:mm") }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="Status">
                      {{ tr.ClosingDate | formatDate("yyyy/MM/dd HH:mm") }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <vs-chip
                      v-if="tr.PaymentStatus"
                      :color="getOrderStatusColor(tr.PaymentStatus)"
                      class="PaymentStatus"
                    >
                      <p>{{ $t(tr.PaymentStatus) }}</p>
                    </vs-chip>
                  </vs-td>

                  <vs-td>
                    <p class="Status">{{ $t(tr.Status) }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="DriverPaymentStatus">
                      {{ $t(tr.DriverPaymentStatus) }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="Rating">{{ tr.Rating | numFormat("0.00") }}</p>
                  </vs-td>

                  <vs-td class="whitespace-no-wrap">
                    <router-link
                      :to="{ name: 'BiadjoDetails', params: { Id: tr.id } }"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    >
                      <feather-icon
                        icon="ArchiveIcon"
                        svgClasses="w-5 h-5 hover:text-primary stroke-current"
                        style="padding: 2px;"
                      />
                    </router-link>
                  </vs-td>

                  <template  slot="expand">
                    <div class="con-expand-users w-full">
                      <div class="vx-row w-full ">
                        <vs-button
                          class="m-2"
                          @click="NavigateTo('ClientProfile', tr.UserId)"
                          >{{ $t("ViewUserDetails") }}</vs-button
                        >

                        <vs-button
                          v-show="tr.DriverId"
                          class="m-2"
                          @click="NavigateTo('DriverProfile', tr.DriverId)"
                          >{{ $t("ViewDriverDetails") }}</vs-button
                        >
                        <vs-button
                          v-show="tr.ClientCompanyId"
                          class="m-2"
                          @click="
                            NavigateTo(
                              'ClientCompanyDetails',
                              tr.ClientCompanyId
                            )
                          "
                        >
                          {{ $t("ClientCompanyDetails") }}</vs-button
                        >

                        <vs-button
                          v-show="tr.FleetCompanyId"
                          class="m-2"
                          @click="
                            NavigateTo(
                              'FleetCompaniesDetails',
                              tr.FleetCompanyId
                            )
                          "
                        >
                          {{ $t("FleetCompaniesDetails") }}
                        </vs-button>
                      </div>

                      <div
                        class="con-btns-user flex items-center justify-between"
                      >
                        <table>
                          <tr>
                            <td class="font-semibold">
                              {{ $t("TotalPrice") }}
                            </td>
                            <td>
                              {{ tr.Total | numFormat("###,##0.00") }}
                              {{ $t(tr.CountryCode + "_Currency") }}
                            </td>
                          </tr>

                          <tr>
                            <td class="font-semibold">{{ $t("Date") }}</td>
                            <td>
                              {{ tr.Date | formatDate("yyyy/MM/dd HH:mm") }}
                            </td>
                          </tr>
                        </table>

                        <table>
                          <tr>
                            <td class="font-semibold">
                              {{ $t("NumberOfWorkers") }}
                            </td>
                            <td>
                              {{ tr.NumberOfWorkers }}
                            </td>
                          </tr>

                          <tr>
                            <td class="font-semibold">
                              {{ $t("NumberOfStops") }}
                            </td>
                            <td>{{ tr.NumberOfTrips }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </template>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </vx-card>
    </div>
    </template>

<script>
import moduleBiadjo from "@/store/biadjo/moduleBiadjo.js";

export default {
  data() {
    return {
      selected: [],
     // CanceledBiadjos:[],
      itemsPerPage: 10,
      isMounted: false,
      BiadjoModel: {
        CountryCode: "",
        Status: "",
        UserId: ""
      },
      addNewDataSidebar: false,
      sidebarData: {},
      UserID: "",
      Biadjo: []
    };
  },
  computed: {
    CanceledBiadjos(){
      return this.$store.state.BiadjoList.CanceledBiadjos;

    },

    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.CanceledBiadjos.length;
    }
  },

  methods: {
    getOrderStatusColor(status) {
      if (status == "PS3") {
        return "#F8BD30";
      }
      if (status == "PS1") {
        return "#039BE5";
      }
      if (status == "PS2") {
        return "#F8BD30";
      }
      // return "primary";
    },

  },
  created() {

    if (!moduleBiadjo.isRegistered) {
      this.$store.registerModule("BiadjoList", moduleBiadjo);
      moduleBiadjo.isRegistered = true;
    }
    this.$store
      .dispatch("BiadjoList/GetCanceleTrips")
      .then(() => {
        this.$vs.loading.close();
      })
      .catch(() => {
        this.$vs.loading.close();
      });
  },
};

</script>

<style>

</style>
